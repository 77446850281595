<template>
  <div class="home">
    <header>
      <el-row>
        <el-col :span="2">
          <div class="logo">
            <a href="http://www.salvater.cn/" target="_blank">
              <img src="../assets/img/logo.png" alt="" />
            </a>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="grid-content">
            <a href="http://www.salvater.cn/" target="_blank">主页</a>
          </div>
          <div class="grid-content">
            <a href="https://tinypng.com/" target="_blank">图片压缩</a>
          </div>
          <div class="grid-content">
            <a href="https://www.salvater.cn/index.php/start-page.html">关于</a>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="title">
      <div>
        <div class="Tips">
          <i class="el-icon-warning-outline"></i>
          注意：单张图片不能超过2M，只支持jpg或者png格式，上传的图片将公开显示，图片链接有效期为2年，上传博彩等违法信息的，直接提交公安机关处理。
        </div>
      </div>
    </div>
    <main>
      <el-upload
        class="upload-demo"
        v-loading="loading"
        element-loading-text="正在长传中。。。"
        element-loading-spinner="el-icon-loading"
        drag
        action=""
        multiple
        :http-request="upload"
        :show-file-list="false"
        :before-upload="beforeUp"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </main>
    <footer>
      <h6>
        Copyright © 2017-2021 Powered by SAUL图床 |
        <a
          target="_blank"
          href="http://wpa.qq.com/msgrd?v=3&uin=2373083508&site=qq&menu=yes"
          >联系我</a
        >
        | <a href="http://www.salvater.cn">主页</a>
      </h6>
    </footer>

    <el-drawer
      direction="ttb"
      :visible.sync="drawer"
      size="auto"
      :show-close="false"
      :withHeader="false"
    >
      <div class="Body">
        <div class="el-div el-Left">
          <div class="bg" :style="{ background: 'url(' + img + ')' }" />
        </div>
        <div class="el-div el-Right">
          <el-form label-position="right" label-width="80px" :model="fromData">
            <el-form-item
              :label="item.label"
              v-for="(item, index) in inputList"
              :key="index"
            >
              <el-input v-model="fromData[item.model]" />
              <el-button
                type="primary"
                :class="item.model"
                :data-clipboard-text="fromData[item.model]"
                @click="copy('.' + item.model)"
                v-text="'复制'"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      drawer: false,
      loading: false,
      img: "",
      fromData: {
        url: "",
        enUrl: "",
        html: "",
        md: "",
      },
      inputList: [
        {
          label: "URL",
          model: "url",
        },
        {
          label: "编码URL",
          model: "enUrl",
        },
        {
          label: "HTML",
          model: "html",
        },
        {
          label: "Markdown",
          model: "md",
        }
      ],
      qiNiu: "http://img.salvater.cn/",
      token: window.localStorage.getItem("key"),
      num: 0,
    };
  },
  created() {
    let key = window.localStorage.getItem("key");
    if (!key) {
      this.getToken("/token");
    }
  },
  methods: {
    async getToken(api) {
      await this.$api
          .get(api)
          .then((res) => {
            let key = res.data.data
            window.localStorage.setItem("key", key);
            this.token = window.localStorage.getItem("key");
          })
          .catch((err) => {
            const m = err || "异常错误请联系管理员！";
            this.$message({
              message: m,
              type: "error",
            });
            console.log(err);
          });
    },
    beforeUp(file) {
      const fileType = file.type === "image/jpeg" || file.type === "image/png";
      const fileSize = file.size / 1024 / 1024 < 1;
      if (!fileSize || !fileType) {
        this.$message({
          type: "error",
          message: "文件格式错误（仅支持png/jpg，并且不能大于1M）",
        });
      }
      return fileType && fileSize;
    },
    upload(e) {
      this.loading = true;
      this.num++;
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let fileData = new FormData();
      fileData.append("file", e.file);
      fileData.append("token", this.token);
      fileData.append("key", e.file.name);
      this.$api
        .post("http://upload-z2.qiniup.com", fileData, config)
        .then((res) => {
          let href = res.data.key;
          let link = this.qiNiu + href;
          let enUrl = this.qiNiu + encodeURIComponent(href)
          this.img = link;
          this.fromData = {
            url: link,
            enUrl: enUrl,
            html: '<a href="' + link + '"></a>',
            md: "![](" + link + ")",
          };
          this.loading = false;
          this.drawer = true;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            if (this.num > 2) {
              this.loading = false;
              this.$message({
                message: "异常错误，请重新上传！",
                type: "warning",
              });
            } else {
              this.getToken("/newToken");
              this.upload(e);
            }
          }
        });
    },
    copy(val) {
      let clipboard = new this.$clipboard(val);
      this.$nextTick(() => {
        clipboard.on("success", (e) => {
          console.log(e.text);
          this.$message({
            message: "复制成功！",
            type: "success",
          });
          // 释放内存
          clipboard.destroy();
        });
        clipboard.on("error", (e) => {
          this.$message({
            message: "复制失败！请手动复制。" || e,
            type: "error",
          });
          // 释放内存
          clipboard.destroy();
        });
      });
    },
  },
};
</script>

<style lang="scss">
.home {
  position: fixed;
  background: url("../assets/img/bg_1.jpg") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  header {
    .el-row {
      background: rgba(0, 0, 0, 0.6);
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 5px #888888;
      padding-top: 2.5px;
      .logo {
        img {
          width: 100%;
        }
      }
      .el-col {
        display: flex;
        justify-content: flex-end;
        .grid-content {
          padding: 10px 10px;
          border-bottom: 2.5px solid rgba(0, 0, 0, 0);
          a {
            text-decoration: unset;
            color: #ffffff;
            display: block;
            line-height: 50px;
          }
        }
        .grid-content:hover {
          border-bottom: 2.5px solid #3695d3;
        }
        .grid-content a:hover {
          color: #3695d3;
        }
      }
    }
  }
  .title {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
    .Tips {
      line-height: 30px;
      background: rgba(0, 0, 0, 0.6);
      margin: 0 auto;
      color: #ffa100;
      box-shadow: 0 0 2px #888888;
      border: 1px solid #888888;
      border-radius: 5px;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: left;
      padding: 18px;
    }
  }
  main {
    width: 95%;
    margin: 0 auto;
    padding: 10px;
    margin-top: 10%;
    .el-upload {
      width: 95%;
      .el-upload-dragger {
        background: rgba(0, 0, 0, 0.6);
        width: 100%;
        border: unset;
        box-shadow: 0 0 10px #888888;
        overflow: hidden;
        border-radius: 10px;
        .el-icon-upload {
          color: #e7e7e7;
        }
        .el-upload__text {
          color: #e7e7e7;
        }
      }
    }
    .el-loading-mask {
      background: rgba(0, 0, 0, 0.6);
      width: 95%;
      margin: 0 auto;
      .el-loading-spinner {
        margin-top: -40px;
      }
      .el-loading-spinner i {
        font-size: 50px;
      }
    }
  }
  footer {
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    a {
      text-decoration: none;
      color: #ffffff;
    }
  }
  .el-drawer {
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 5px #888888;
    .Body {
      margin: 0 auto;
      padding: 10px;
      display: flex;
      justify-content: center;
      .el-Left {
        width: 30%;
        height: 240px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .bg {
          height: 200px;
          width: 90%;
          background-repeat: no-repeat !important;
          background-size: contain !important;
          overflow: hidden;
          margin: 0 auto;
        }
      }
      .el-Right {
        height: 240px;
        width: 68%;
        .el-form-item__label {
          color: #ffffff;
        }
        .el-form-item {
          margin-bottom: 0;
          padding: 20px;
        }
        .el-form-item__content {
          display: flex;
        }
        .el-input {
          margin-right: 20px;
        }
      }
    }
  }
  //自适应
  @media screen and (max-width: 750px) {
    .Body {
      display: unset !important;
      margin: 0 !important;
      padding: 0 !important;
      .el-Left {
        margin: 0 auto !important;
        width: 90% !important;
      }
      .el-Right {
        margin: 0 auto !important;
        width: 90% !important;
      }
    }
  }
}
.v-modal {
  display: none !important;
}
</style>
