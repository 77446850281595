import axios from "axios";

const server = axios.create({
  timeout: 5000,
  // baseURL: "http://key.salvater.cn:8888",
    baseURL: "http://upload.salvater.cn:8888/",
  // baseURL: "http://129.204.37.160:8888",
  // baseURL: "http://172.20.10.2:8888", //
});


// 请求拦截器
server.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
  }
);

server.interceptors.response.use(
  // 获取响应数据
  (response) => {
    let res = {};
    res.data = response.data;
    res.status = response.status;
    return res;
  }
  // (error) => {
  //   // return Promise.reject(error);
  //   return console.log(error);
  // }
);
export default server;
