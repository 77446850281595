import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "../src/assets/styles/index.scss"; // global css

import api from "../src/api/request";

// import Clipboard from "v-clipboard";
// import clipboard from 'clipboard';
import clipboard from "clipboard";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.prototype.$clipboard = clipboard;

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
